const PAYPAL = {
    onReady() {
        if (document.getElementById('paypal-button-container') === null) return;
        const getUrl  = window.location;
        const baseUrl = getUrl.protocol + '//' + getUrl.hostname + '/';
        paypal.Buttons({
            createOrder() {
                return fetch(baseUrl + 'inc/handler.XHR.php?handler=paypal&method=create', {
                    method: 'post',
                    headers: {
                        'content-type': 'application/json'
                    }
                }).then(res => {
                    return res.json();
                }).then(data => {
                    return data.id;
                });
            },
            onApprove(data) {
                return fetch(baseUrl + 'inc/handler.XHR.php?handler=paypal&method=capture', {
                    headers: {
                        'content-type': 'application/json'
                    }
                }).then(res => {
                    return res.json();
                }).then(details => {
                    console.log(details);
                    // window.location.href = baseUrl + 'order-overview/?order-id=' + details.order.merchantReference + '&email=' + details.order.recipient.email;
                    window.location.href = baseUrl + 'bestellung?response=payed';
                })
            },
            onError(err) {
                console.table(err);
                switch (err.message) {
                    case 'Detected popup close' :
                        // window.location.reload();
                        break;
                    default :
                        window.location.href = baseUrl + 'bestellung?response=error';
                        break;
                }
            }
        }).render('#paypal-button-container');
    }
}
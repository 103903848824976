const BULMA = {
    onReady() {
        let notificationClosers = document.querySelectorAll('.notification .delete');
        notificationClosers.forEach(node => {
            node.addEventListener('click', () => {
                BULMA.closeNotification(node);
            });
        });
        let radioButtons = document.querySelectorAll('.is-radio');
        radioButtons.forEach(node => {
            node.addEventListener('click', () => {
                BULMA.changeRadioStatus(node);
            });
        });
    },
    closeNotification(that) {
        that.closest('.notification').remove();
    },
    changeRadioStatus(that) {
        let name   = that.querySelector('input').name;
        let inputs = document.querySelectorAll('.is-radio input[name="'+ name +'"]');
        inputs.forEach(node => {
            node.closest('.is-radio').classList.remove('is-info');
        });
        that.classList.add('is-info');
    }
}
const QTY = {
    increase(that) {
        const field = that.parentNode.querySelector('[type=number]');
        field.value = parseInt(field.value)+1;
    },
    decrease(that) {
        const field = that.parentNode.querySelector('[type=number]');
        if (parseInt(field.value)-1 < 1) return;
        field.value = parseInt(field.value)-1;
    }
}
const FORM = {
    onReady() {
        this.regexEmail = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        this.regexDate  = new RegExp(/^\d{4}-\d{2}-\d{2}$/);
        this.regexPhone = new RegExp(/^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/);
    },
    /*********************
    * Field validation
    *********************/
    validateField(that) {
        var container = that.closest('[data-toggle]') === null ? that.closest('.field') : that.closest('[data-toggle]');
        if (!container.classList.contains('is-hidden')) {
            var error = 0;
            switch (that.type) {
                case 'text' :
                    (that.value === '') && (error++);
                    break;
                case 'email' :
                    (FORM.regexEmail.test(that.value) !== true) && (error++);
                    break;
                case 'tel' :
                    (FORM.regexPhone.test(that.value) !== true) && (error++);
                    break;
                case 'password' :
                    (that.value.length < 7) && (error++);
                    break;
                case 'date' :
                    // frontend 21.03.1992 becomes 1992-03-21 
                    (FORM.regexDate.test(that.value) !== true) && (error++);
                    (that.value >= that.max) && (error++);
                    break;
                case 'checkbox' :
                    (!that.checked) && (error++);
                    break;
                default :
                    console.log('unknown type');
                    break;
            }
            if (error === 0) {
                FORM.removeError(that);
                FORM.addSuccess(that);
                return true;
            } else {
                FORM.addError(that);
                return false;
            }
        } else {
            FORM.removeError(that);
            return true;
        }
    },
    /*********************
    * Form validation
    *********************/
    validateAll({that, event, redirect = false}) {
        (that !== null) && (that.disabled = true);
        event.preventDefault();
        OVERLAY.loading();
        let form     = that.getAttribute('form') != null && that.getAttribute('form') != '' ? document.getElementById(that.getAttribute('form')) : that.closest('form');
        let email    = form.querySelector('input[type="email"]');
        let phone    = form.querySelector('input[type="tel"]');
        let password = form.querySelector('input[type="password"]');
        // reset the form
        let inputs    = [...form.getElementsByTagName('input')]; // Array.from(htmlCollection);
        let textareas = [...form.getElementsByTagName('textarea')]; // Array.from(htmlCollection);
        let helps     = [...form.querySelectorAll('.help.is-danger')]; // Array.from(htmlCollection);
        inputs.forEach(node => {
            node.classList.remove('error');
            node.classList.remove('is-danger');
        });
        textareas.forEach(node => {
            node.classList.remove('error');
            node.classList.remove('is-danger');
        });
        helps.forEach(node => {
            node.remove();
        });
        var error = 0;
        // check required
        inputs.forEach(node => {
            if (node.required && node.closest('.field') !== null) {
                if (node.closest('.field').classList.contains('is-hidden')) return;
            }
            if (node.required && node.value === '') {
                node.classList.add('error');
                error++;
                FORM.addError(node);
            } else if (node.required && node.type === 'radio') {
                let radios = document.querySelectorAll('input[name="'+ node.name +'"]:checked');
                if (radios.length < 1) {
                    error++;
                    FORM.addError(node);
                }
            } else if (node.required && node.type === 'checkbox' && !node.checked) {
                error++;
                FORM.addError(node);
            }
        });
        textareas.forEach(node => {
            if (node.required && node.value === '') {
                error++;
                FORM.addError(node);
            }
        });
        // check email format
        if (email !== null) {
            if (FORM.regexEmail.test(email.value) !== true && email.required) {
                error++;
                FORM.addError(email);
            }
        }
        // check phone format
        if (phone !== null) {
            if (FORM.regexPhone.test(phone.value) !== true && phone.required) {
                error++;
                FORM.addError(phone);
            }
        }
        // check password length
        if (password !== null) {
            if (password.value.length < 7 && password.required) {
                error++;
                FORM.addError(password);
            }
        }
        // proceed or return
        if (error > 0) {
            document.getElementById('overlay-loading').remove();
            form.classList.add('error');
            (that !== null) && (that.disabled = false);
            FORM.addError(that);
            return;
        } else {
            let submitButton = form.querySelector('button');
            (submitButton !== null) && (submitButton.disabled = true);
            form.classList.remove('error');
            if (redirect) { // URL
                document.getElementById('overlay-loading').remove();
                window.location = redirect;
            } else {
                FORM.sendData(form);
            }
        }
    },
    /*********************
    * Add Error Message to Field
    *********************/
    addError(that) {
        that.classList.add('error');
        that.classList.add('is-danger');
        that.classList.remove('is-success');
        let form    = that.getAttribute('form') != null && that.getAttribute('form') != '' ? document.getElementById(that.getAttribute('form')) : that.closest('form');;
        let control = that.closest('.control');
        let field   = that.closest('.field');
        if (field === null) return;
        // Check if error is already present
        let presentErrorMessages = form.querySelector('.is-danger[data-node="'+ field.dataset.field +'"');
        if (presentErrorMessages !== null) return;
        // Build message
        if (typeof field.dataset.error != 'undefined') {
            field.insertAdjacentHTML('afterEnd', `<p class='help is-danger mt-n100 mb-100' data-node='${field.dataset.field}'>${field.dataset.error}</p>`);
        }
        if (control === null) return;
        control.classList.remove('has-icons-right');
        let iconRight = control.querySelector('.icon.is-right');
        if (iconRight === null) return;
        iconRight.remove();
    },
    removeError(that) {
        that.classList.remove('error');
        that.classList.remove('is-danger');
        let form  = that.getAttribute('form') != null && that.getAttribute('form') != '' ? document.getElementById(that.getAttribute('form')) : that.closest('form');;
        let field = that.closest('.field');
        if (field === null) return;
        let presentErrorMessage = form.querySelector('.is-danger[data-node="'+ field.dataset.field +'"');
        if (presentErrorMessage === null) return;
        presentErrorMessage.remove();
    },
    addSuccess(that) {
        that.classList.add('is-success');
        let control = that.closest('.control');
        if (control === null) return;
        // Only add checkmark to fields not checkboxes or radios
        if (['text', 'number', 'email', 'tel'].includes(that.type)) {
            control.classList.add('has-icons-right');
            control.insertAdjacentHTML('beforeEnd', `<span class="icon is-small is-right" style="color:var(--colorSuccess)"><ion-icon name="checkmark-sharp"></ion-icon></span>`);
        }
    },
    /*********************
    * Show the response
    *********************/
    response(form, node) {
        (form.previousElementSibling !== null) && form.previousElementSibling.remove(); // form head text
        (form.nextElementSibling !== null) && form.nextElementSibling.remove(); // data privacy disclaimer
        form.parentNode.insertBefore(node, form.nextSibling);
        form.remove();        
    },
    /*********************
    * Sends the data over a XHR request
    *********************/
    sendData(form) {
        if (typeof form.dataset.redirect != 'undefined') {
            window.location = form.dataset.redirect;
        }
        let getUrl   = window.location;
        let baseUrl  = getUrl.protocol + '//' + getUrl.hostname + '/';
        let formData = FORM.serialize(form);
        let handler  = 'handler.XHR.php?handler=' + form.dataset.handler;
        let xhr      = new XMLHttpRequest();
        xhr.open('POST', baseUrl + 'inc/' + handler, true);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xhr.onload = (e) => {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                    var responseNode = form.querySelector('.form__responses [data-response="' + xhr.response + '"]').content.cloneNode(true);
                } else {
                    var responseNode = form.querySelector('.form__responses [data-response="error"]').content.cloneNode(true);
                }
                FORM.response(form, responseNode);
                document.getElementById('overlay-loading').remove();
            }
        };
        xhr.onerror = (e) => {
            var responseNode = form.querySelector('.form__responses [data-response="error"]').content.cloneNode(true);
            FORM.response(form, responseNode);
            document.getElementById('overlay-loading').remove();
        };
        xhr.send(formData);
        console.log(xhr);
    },
    /*********************
    * Input serialiser 
    *********************/
    serialize(form) {
        var serialized = [];
        for (var i = 0; i < form.elements.length; i++) {
            var field = form.elements[i];
            if (!field.name || field.disabled || field.type === 'file' || field.type === 'reset' || field.type === 'submit' || field.type === 'button') continue;
            if (field.type === 'select-multiple') {
                for (var n = 0; n < field.options.length; n++) {
                    if (!field.options[n].selected) continue;
                    serialized.push(encodeURIComponent(field.name) + '=' + encodeURIComponent(field.options[n].value));
                }
            } else if ((field.type !== 'checkbox' && field.type !== 'radio') || field.checked) {
                serialized.push(encodeURIComponent(field.name) + '=' + encodeURIComponent(field.value));
            }
        }
        return serialized.join('&');
    }
}
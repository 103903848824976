const XHR = {
    /**
    * @nodeIds {array} - IDs of elements which should get refreshed 
    * @url {string} - the Url which should be could
    * @functionCall {array} - array of functions which should be called after the XHR refresh
    */
    load({ nodeIds, url = window.location, functionCall = null }) {
        const xhr = new XMLHttpRequest();
        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                    // Build new content
                    let responseText  = xhr.responseText;
                    let htmlContent   = responseText.match(/(?=<html[^>]*>).*?(<\/html>)/gs);
                    let tempDOM       = document.createElement('html');
                    tempDOM.innerHTML = `<html>${htmlContent}</html>`;
                    // Multiple Ids possible
                    if (Array.isArray(nodeIds) ) {
                        nodeIds.forEach(nodeId => {
                            let responseNode = tempDOM.querySelector(`#${nodeId}`);
                            let refreshNode  = document.getElementById(nodeId);
                            if (responseNode === null || refreshNode === null) {
                                (responseNode === null) && console.log('responseNode "'+nodeId+'" is null');
                                (refreshNode === null) && console.log('refreshNode "'+nodeId+'" is null');
                                return false;
                            }
                            // Put new content into site
                            refreshNode.innerHTML = responseNode.innerHTML;
                        });
                    } else {
                        console.log('Error: You must provide an array of ids');
                        return false;
                    }
                    // Call Functions
                    if (functionCall !== null && Array.isArray(functionCall)) {
                        functionCall.forEach(call => {
                            call();
                        });                        
                        return true;
                    } else {
                        return true; // return true even though no function is called
                    }
                } else if (xhr.status === 400) {
                    console.log('XHR Status: 400');
                    return false;
                } else {
                    console.log('XHR Status: Something else other than 200 was returned');
                    return false;
                }
            }
        };
        // Asynchron
        xhr.open('GET', url, true);
        xhr.send();
    }
}
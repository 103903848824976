const COOKIE = {
    /*********************
    * cookieDomain is a constant - it's init in the functions.php and set in the class.Config.php
    *********************/
    set({name, value, days}) {
        let expires = new Date();
        expires.setTime(expires.getTime() + (days * 86400000)); // 24 * 60 * 60 * 1000 (milliseconds)
        document.cookie = name + '=' + value + ';domain=' + cookieDomain + ';expires=' + expires.toUTCString() + ';path=/;secure=true;samesite=lax;';
    },
    get({name}) {
        var cookieName = name + '=';
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(cookieName) == 0) {
                return c.substring(cookieName.length, c.length);
            }
        }
        return '';
    },
    delete({name}) {
        document.cookie = name + '=;domain=' + cookieDomain + ';expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/';
    }
}
const OVERLAY = {
    loading() {
        let markup = `
        <div class="overlay--loading" id="overlay-loading">
            <div id="loading">
                <div class='overlay--loading__container--loader'>
                    <div class="my-loader">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>
        </div>`;
        document.body.insertAdjacentHTML('afterbegin', markup);
    }
}
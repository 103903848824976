const TRACKING = {
    cookieName: '_user_cookie_settings',
    optin: document.getElementById('optin'),
    buttons: document.querySelectorAll('.optin__button'),
    settings: document.getElementById('optin__settings'),
    showSettings: document.getElementById('optin__show-settings'),
    onReady() {
        if (TRACKING.optin !== null) {
            TRACKING.showOptin();
            TRACKING.showOptions();
            TRACKING.acceptOptin();
        }
    },
    showOptin() {
        if ( document.cookie.indexOf(TRACKING.cookieName) != -1 ) {
            TRACKING.optin.remove();
            let cookieValueStr = COOKIE.get({name: TRACKING.cookieName});
            let cookieValueArr = cookieValueStr.split(',');
            TRACKING.activateTracking({settings: cookieValueArr});
        } else {
            TRACKING.optin.style.display = 'block';
        }
    },
    acceptOptin() {
        TRACKING.buttons.forEach(button => { 
            button.addEventListener('click', event => {
                event.preventDefault();
                let cookieSettingsArr = [];
                TRACKING.settings.querySelectorAll('input').forEach(element => {
                    (element.checked) ? cookieSettingsArr.push(element.value + '=true') : cookieSettingsArr.push(element.value + '=false');                    
                });
                const storeDuration     = 30; // Days
                const cookieSettingsStr = cookieSettingsArr.toString();
                COOKIE.set({
                    name: TRACKING.cookieName, 
                    value: cookieSettingsStr, 
                    days: storeDuration
                });
                TRACKING.optin.remove();
                location.reload();
            });
        });
    },
    showOptions() {
        TRACKING.showSettings.addEventListener('click', () => {
            TRACKING.settings.style.display = 'block';
            TRACKING.showSettings.remove();
            TRACKING.settings.querySelectorAll('input').forEach( el => {
                el.checked = false;
            });
        });
    }
}